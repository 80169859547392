// Custom jQuery
// -----------------------------------


(function(window, document, $, undefined) {
    'use strict';

    $(initCustom);

    function initCustom() {

        // custom code

    }

})(window, document, window.jQuery);