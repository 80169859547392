// TRANSLATION
// -----------------------------------

(function(window, document, $, undefined) {
    'use strict';

    $(initTranslation);


    var preferredLang = 'en';
    var pathPrefix = '/Content/i18n'; // folder of json files
    var packName = 'site';
    var storageKey = 'jq-appLang';

    function initTranslation() {

        if (!$.fn.localize) return;

        // detect saved language or use default
        var currLang = Storages.localStorage.get(storageKey) || preferredLang;
        // set initial options
        var opts = {
            language: currLang,
            pathPrefix: pathPrefix,
            callback: function(data, defaultCallback) {
                Storages.localStorage.set(storageKey, currLang); // save the language
                defaultCallback(data);
            }
        };

        // Set initial language
        setLanguage(opts);

        // Listen for changes
        $('[data-set-lang]').on('click', function() {

            currLang = $(this).data('setLang');

            if (currLang) {

                opts.language = currLang;

                setLanguage(opts);

                activateDropdown($(this));
            }

        });


        function setLanguage(options) {
            $("[data-localize]").localize(packName, options);
        }

        // Set the current clicked text as the active dropdown text
        function activateDropdown(elem) {
            var menu = elem.parents('.dropdown-menu');
            if (menu.length) {
                var toggle = menu.prev('button, a');
                toggle.text(elem.text());
            }
        }

    }

})(window, document, window.jQuery);